import React, { useEffect, useState } from 'react';
import Image from "next/legacy/image";

import { PartnerLogo } from '../../types/MarketingPages';
import { CarouselProvider, Slide, Slider } from 'pure-react-carousel';

import styles from './PartnerLogos.module.scss';
import 'pure-react-carousel/dist/react-carousel.es.css';

interface PartnerLogosProps {
  logos: PartnerLogo[];
}

export const PartnerLogos: React.FC<PartnerLogosProps> = ({ logos }) => {
  const [prefersReducedMotion, setPrefersReducedMotion] = useState<boolean>(false);
  const [numberVisibleSlides, setNumberVisibleSlides] = useState<number>(5);

  useEffect(() => {
    const accessibilityQuery =
      typeof window !== 'undefined' ? window.matchMedia('(prefers-reduced-motion: reduce)') : null;

    const handleReduceMotionChanged = (event: MediaQueryListEvent) => {
      setPrefersReducedMotion(event.matches);
    };

    if (accessibilityQuery) {
      setPrefersReducedMotion(accessibilityQuery.matches);
      accessibilityQuery.addEventListener('change', handleReduceMotionChanged);
    }

    const handleScreenSizeChanged = () => {
      const width = window.innerWidth;
      // The width of the slide is set to 200 and we add extra for padding
      const numberSlides = Math.floor(width / 280);
      setNumberVisibleSlides(numberSlides);
    };

    // Call the handler right away so the number of slides is set correctly initially
    handleScreenSizeChanged();
    window.addEventListener('resize', handleScreenSizeChanged);

    return () => {
      if (accessibilityQuery)
        accessibilityQuery.removeEventListener('change', handleReduceMotionChanged);
      window.removeEventListener('resize', handleScreenSizeChanged);
    };
  }, []);

  return (
    <CarouselProvider
      naturalSlideWidth={200}
      naturalSlideHeight={80}
      isPlaying={!prefersReducedMotion}
      interval={3000}
      totalSlides={logos.length}
      visibleSlides={Math.min(logos.length, numberVisibleSlides)}
    >
      <Slider className={styles.partnerLogos}>
        {logos.map((logo, index) => (
          <Slide key={logo.name} index={index}>
            <div className={styles.logo}>
              <div className={styles.imageWrapper}>
                <Image
                  src={logo.source}
                  alt={`${logo.name} logo`}
                  layout="fill"
                  objectFit="contain"
                  loading="lazy"
                />
              </div>
            </div>
          </Slide>
        ))}
      </Slider>
    </CarouselProvider>
  );
};

PartnerLogos.displayName = 'PartnerLogos';
