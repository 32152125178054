type Announcements = {
  [key: string]: {
    description: string;
    lead: string;
  };
};

export const announcements: Announcements = {
  LAUNCH_ANNOUNCEMENT: {
    description:
      'For users outside of Houston, sign up and we will notify you when you will be able to find service providers or clients in your area!',
    lead: 'We are now launched in Houston, TX!',
  },
};
