import { announcements } from './Announcements';

import { AnnounceIcon } from '../../images/AnnounceIcon';

import styles from './AnnounceBox.module.scss';

export function AnnounceBox() {
  const announcementKey = globalThis.Rose.config.NEXT_PUBLIC_ANNOUNCE_BOX_ANNOUNCEMENT;

  if (!announcementKey || !announcements[announcementKey]) {
    return null;
  }

  const announcementContent = announcements[announcementKey];
  return (
    <div className={styles.announce}>
      <div className={styles.announceIcon}>
        <AnnounceIcon />
      </div>
      <p className={styles.text}>
        {announcementContent.lead}
        <span className={styles.announcementDescription}>{announcementContent.description}</span>
      </p>
    </div>
  );
}
