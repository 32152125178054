const testimonials = [
  {
    id: 'empowerment',
    content: (
      <>
        <p>
          Working with the staff at Rosarium Health for my dad's bathroom modification and
          renovation was a great experience.
        </p>
        <p>
          They recommended safety aids for my father while waiting for approval from the Veterans
          Administration. They referred us to a number of contractors from whom we obtained bids.
          They walked with us through the entire process with both the VA and the contractors. The
          contractor we selected responded quickly and developed a scope of work in a matter of
          days.
        </p>
        <p>
          We are pleased with the customer service from Rosarium as we navigated the process. We
          would utilize their services again.
        </p>
      </>
    ),
  },
  {
    id: 'speed',
    content: (
      <>
        <p>
          [Working with Rosarium Health] was a very good experience for me and the COPE
          representatives that I am currently working with.
        </p>
        <p>
          The contractor Rosarium asked to do the work quickly responded and completed the job
          within a week. This was quite a pleasant surprise as I have been collaborating to get work
          done with the VA the duration of my husband's disability the past 12+ years. Minimal
          response time has typically been 6-8 weeks.
        </p>
      </>
    ),
  },
  {
    id: 'reliability',
    content: (
      <>
        <p>
          I'm 100% disabled veteran, have PTSD bad, and this house was driving me crazy and I felt
          really depressed in it so Rosarium Health started helping me with everything to get this
          house fixed up.
        </p>
        <p>
          Rosarium health team has kept me updated on everything and helped me through the process
          of getting this house livable for me. I really believe the Rosarium Health team has gone
          out of their way to help me.
        </p>
      </>
    ),
  },
];

export default testimonials;
