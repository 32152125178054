import { AccessibleIcon } from './AccessibleIcon';

type Props = {
  className?: string;
  height?: string;
  width?: string;
};

export function AnnounceIcon({ className, height = '21', width = '21' }: Props) {
  return (
    <AccessibleIcon label="announce">
      <svg
        className={className}
        width={width}
        height={height}
        viewBox="0 0 21 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.5 4.38218V17.7402C9.5 18.7121 8.7121 19.5 7.74018 19.5C6.99646 19.5 6.33302 19.0325 6.08288 18.3321L3.93647 12.1829M16.5 11.5C18.1569 11.5 19.5 10.1569 19.5 8.5C19.5 6.84315 18.1569 5.5 16.5 5.5M3.93647 12.1829C2.5043 11.5741 1.5 10.1543 1.5 8.5C1.5 6.29086 3.29086 4.5 5.49999 4.5H7.33208C11.4327 4.5 14.9569 3.2659 16.5 1.5L16.5 15.5C14.9569 13.7341 11.4327 12.5 7.33208 12.5L5.49998 12.5C4.94518 12.5 4.41677 12.387 3.93647 12.1829Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </AccessibleIcon>
  );
}
