import { CommonModal } from '../templates/modals/common/CommonModal';
import styles from './ConfirmModal.module.scss';
import { useSiteStore } from '../../store/site';
import { observer } from 'mobx-react-lite';
import { CommonModalFooter } from '../templates/modals/common/CommonModalFooter';
import React from 'react';
import { CommonModalContent } from '../templates/modals/common/CommonModalContent';
import { useKeyPress } from '../../hooks/useKeyPress';
import classNames from 'classnames';
import { CommonModalHeader } from '../templates/modals/common/CommonModalHeader';

type Props = {};

export const ConfirmModal: React.FC<Props> = observer(() => {
  const site = useSiteStore();

  const enterPressed = useKeyPress('enter');

  if (enterPressed) {
    site.onConfirm();
    return null;
  }

  if (!site.confirmProps) return null;
  const props = site.confirmProps;
  const { title, hideCancel } = props;
  const okLabel = props.okLabel || 'Okay';
  const cancelLabel = props.cancelLabel || 'Cancel';

  return (
    <CommonModal onDismiss={site.onConfirmCancel}>
      {title && <CommonModalHeader title={title} />}
      <CommonModalContent>
        <div className={styles.container}>{site.confirmProps.message}</div>
      </CommonModalContent>
      <CommonModalFooter>
        {!hideCancel && (
          <button type="button" className="btn" onClick={site.onConfirmCancel}>
            {cancelLabel}
          </button>
        )}
        <button
          className={classNames('btn red', styles.confirm)}
          type="button"
          onClick={site.onConfirm}
        >
          {okLabel}
        </button>
      </CommonModalFooter>
    </CommonModal>
  );
});

ConfirmModal.displayName = 'ConfirmModal';
