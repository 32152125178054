import { AnnounceIcon } from '../../images/AnnounceIcon';

import styles from './AnnounceManager.module.scss';
import { observer } from 'mobx-react-lite';
import { useUserStore } from '../../store/user';
import { useRouter } from 'next/router';

export const AnnounceManager: React.FC = observer(() => {
  const user = useUserStore();
  const router = useRouter();

  if (!user.isAuthenticated() || !user.getManagerId()) {
    return null;
  }

  return (
    <div className={styles.announce} data-testid="announce-bar">
      <div className={styles.announceBox}>
        <AnnounceIcon />
      </div>
      <span className={styles.text}>
        {' '}
        You are acting on behalf of {user.getName()}{' '}
        <button
          data-testid="stop-impersonating"
          type="button"
          className="btn secondary"
          onClick={async () => {
            await user.stopImpersonating();
            if (!router.pathname.endsWith('/profile-settings/manage-clients')) {
              router.push('/');
            }
          }}
        >
          Stop
        </button>
      </span>
    </div>
  );
});
