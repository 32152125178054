import { AccessibleIcon } from './AccessibleIcon';

type Props = {
  className?: string;
  height?: string;
  width?: string;
};

export function DoubleQuoteIcon({ className, height = '44', width = '56' }: Props) {
  return (
    <AccessibleIcon label="double quote">
      <svg
        className={className}
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
        role="img"
      >
        <path d="M8.956 24.002c1.965 0 3.246-.342 4.698-1.11C11.09 29.381 2.55 40.4 2.55 42.108c0 .769.342.94 1.11.94 6.748 0 21.61-17.168 21.61-30.664C25.27 6.321 22.537 0 14.508 0 5.454 0 .5 8.285.5 14.948.585 19.988 3.148 24 8.956 24Zm30.23 0c1.965 0 3.246-.342 4.698-1.11C41.32 29.381 32.78 40.4 32.78 42.108c0 .769.342.94 1.11.94 6.748 0 21.61-17.168 21.61-30.664C55.5 6.321 52.767 0 44.738 0 35.684 0 30.73 8.285 30.73 14.948c.085 5.04 2.562 9.053 8.456 9.053Z" />
      </svg>
    </AccessibleIcon>
  );
}
