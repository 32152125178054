import React, { ReactNode } from 'react';
import classNames from 'classnames';
import { ButtonBack, ButtonNext, CarouselProvider, Slide, Slider } from 'pure-react-carousel';

import { CarouselLeftIcon } from '../../images/CarouselLeftIcon';
import { CarouselRightIcon } from '../../images/CarouselRightIcon';
import { DoubleQuoteIcon } from '../../images/DoubleQuoteIcon';

import styles from './TestimonialCarousel.module.scss';
import 'pure-react-carousel/dist/react-carousel.es.css';

type Props = {
  testimonials: { id: string; content: ReactNode }[];
};

export const TestimonialCarousel: React.FC<Props> = ({ testimonials }) => (
  <CarouselProvider
    naturalSlideWidth={400}
    naturalSlideHeight={100}
    isIntrinsicHeight
    totalSlides={testimonials.length}
  >
    <div className={styles.carouselWrapper}>
      <ButtonBack className={styles.sideControls}>
        <CarouselLeftIcon />
      </ButtonBack>
      <Slider className={styles.carousel}>
        {testimonials.map((testimonial, index) => (
          <Slide key={testimonial.id} index={index} className={styles.slide}>
            <div className={classNames(styles.quotes, styles.left)}>
              <DoubleQuoteIcon />
            </div>
            {testimonial.content}
            <div className={classNames(styles.quotes, styles.right)}>
              <DoubleQuoteIcon />
            </div>
          </Slide>
        ))}
      </Slider>
      <ButtonNext className={styles.sideControls}>
        <CarouselRightIcon />
      </ButtonNext>
      <div className={styles.bottomControlsWrapper}>
        <ButtonBack className={styles.bottomControls}>
          <CarouselLeftIcon />
        </ButtonBack>
        <ButtonNext className={styles.bottomControls}>
          <CarouselRightIcon />
        </ButtonNext>
      </div>
    </div>
  </CarouselProvider>
);
