import { observer } from 'mobx-react-lite';
import { HTMLAttributes } from 'react';

export type ButtonType =
  | 'primary'
  | 'primary-inverted'
  | 'secondary'
  | 'accept'
  | 'decline'
  | 'link'
  | 'red';

interface Props extends HTMLAttributes<HTMLElement> {
  /**
   * The text to display in the button
   */
  children: React.ReactNode;
  /**
   * The function to call when the button is clicked
   */
  clickHandler?: () => void;
  /**
   * If defined, the button will be an anchor pointing to the specified url
   */
  href?: string;
  /**
   * Additional styling applied to the button. Defaults to 'primary'
   */
  styling?: ButtonType;
  /**
   * Whether the button should be extra round or not
   */
  round?: boolean;
  /**
   * If true, the button will span the full width of its container
   */
  fullWidth?: boolean;
}

export const BaseButton = observer(
  ({
    children,
    clickHandler,
    href,
    styling = 'primary',
    round = false,
    fullWidth = false,
    className = '',
    ...props
  }: Props) => {
    const finalClassName = `btn ${styling}${round ? ' extra-rounded' : ''}${
      fullWidth ? ' full-width' : ''
    }${className}`;

    if (href) {
      // Render as an anchor tag if href is provided
      return (
        <a href={href} className={finalClassName} {...props}>
          {children}
        </a>
      );
    }
    return (
      <button type="button" className={finalClassName} onClick={clickHandler} {...props}>
        {children}
      </button>
    );
  }
);

BaseButton.displayName = 'BaseButton';
