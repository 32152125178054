type Props = {
  className?: string;
  height?: string;
  width?: string;
};

export function CarouselRightIcon({ className, width = '25', height = '38' }: Props) {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 25 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.48462 -6.4895e-08L10.4042 -4.54782e-07C11.3771 0.00162838 12.2877 0.471076 12.8421 1.25699L24.7349 18.1671L24.7349 18.1668C24.9074 18.4115 25 18.7021 25 19C25 19.2978 24.9074 19.5885 24.7349 19.8332L12.8421 36.7433L12.8421 36.743C12.3029 37.508 11.4248 37.9749 10.4785 38L1.55896 38C1.19561 37.9993 0.844863 37.8679 0.573385 37.6307C0.30195 37.3932 0.12807 37.0663 0.0852646 36.7117C0.042127 36.3568 0.132715 35.9989 0.339777 35.7053L11.519 19.8332L11.519 19.8335C11.6915 19.5889 11.7841 19.2982 11.7841 19.0003C11.7841 18.7025 11.6915 18.4118 11.519 18.1671L0.265021 2.29509C0.0579597 2.00149 -0.0326286 1.64361 0.0105081 1.28865C0.0533144 0.93404 0.227194 0.607155 0.498629 0.369656C0.770064 0.132488 1.12081 0.00100703 1.4842 0.000354702L1.48462 -6.4895e-08Z"
        fill="currentColor"
      />
    </svg>
  );
}
