type Props = {
  className?: string;
  height?: string;
  width?: string;
};

export function CarouselLeftIcon({ className, width = '25', height = '38' }: Props) {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 25 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.5154 -6.4895e-08L14.5958 -4.54782e-07C13.6229 0.00162838 12.7123 0.471076 12.1579 1.25699L0.26513 18.1671L0.26513 18.1668C0.0925836 18.4115 -1.06381e-06 18.7021 -1.07683e-06 19C-1.08985e-06 19.2978 0.092574 19.5885 0.26513 19.8332L12.1579 36.7433L12.1579 36.743C12.6971 37.508 13.5752 37.9749 14.5215 38L23.441 38C23.8044 37.9993 24.1551 37.8679 24.4266 37.6307C24.6981 37.3932 24.8719 37.0663 24.9147 36.7117C24.9579 36.3568 24.8673 35.9989 24.6602 35.7053L13.481 19.8332L13.481 19.8335C13.3085 19.5889 13.2159 19.2982 13.2159 19.0003C13.2159 18.7025 13.3085 18.4118 13.481 18.1671L24.735 2.29509C24.942 2.00149 25.0326 1.64361 24.9895 1.28865C24.9467 0.93404 24.7728 0.607155 24.5014 0.369656C24.2299 0.132488 23.8792 0.00100703 23.5158 0.000354702L23.5154 -6.4895e-08Z"
        fill="currentColor"
      />
    </svg>
  );
}
